<template>
  <div class="progress-bar">
    <div class="progress-bar__title">{{ props.title }}</div>
    <div class="progress-bar__loader-container">
      <div class="progress-bar__loader">
        <div
          class="progress-bar__loader-progress"
          :class="{ pulse: showTakesLongerMessage }"
          :style="{ width: `${progressPercentage}%` }"
        />
      </div>
      <div class="progress-bar__loader-percent">{{ progressPercentage }}%</div>
    </div>
    <div v-if="showTakesLongerMessage" class="progress-bar__message">
      It takes longer than expected, be patient!
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Creating logos...',
  },
  duration: {
    type: Number,
    required: true,
  },
});

const progressPercentage = ref(0);
const showTakesLongerMessage = ref(false);
const secondsLeft = ref(props.duration);

onMounted(() => {
  const secondsInterval = setInterval(() => {
    secondsLeft.value -= 1;

    if (secondsLeft.value > 0) {
      progressPercentage.value = Math.floor(
        100 - (secondsLeft.value / props.duration) * 100,
      );
    } else {
      progressPercentage.value = 96;
      showTakesLongerMessage.value = true;

      clearInterval(secondsInterval);
    }
  }, 1000);
});
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  max-width: 468px;

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
    color: var(--dark);
  }

  &__loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__loader-percent {
    width: 41px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }

  &__loader {
    width: 100%;
    height: 12px;
    background-color: var(--primary-light);
    border-radius: 16px;
    position: relative;
  }

  &__loader-progress {
    height: 100%;
    background-color: var(--primary);
    border-radius: 16px;
    transition: width 1s linear;

    &.pulse {
      animation: pulse 2s ease-in-out infinite;
    }
  }

  &__message {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray);
    text-align: center;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
