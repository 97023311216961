<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import CloseIcon from '@/assets/icons/close.svg';
import CheckIcon from '@/assets/icons/check-circle.svg';
import BackIcon from '@/assets/icons/arrow-downV2.svg';
import Modal from '@/components/LogoMakerModal.vue';
import Button from '@/components/LogoMakerButton.vue';
import { sendNpsScore } from '@/api/NpsApi';
import { logEvent } from '@/api/AmplitudeApi';
import {
  LOGO_MAKER_FEEDBACK_SHOWN,
  LOGO_MAKER_FEEDBACK_SENT,
} from '@/constants/amplitude';

const NPS_FORM_TYPE_LOGO_MAKER = 'logo-maker';

const MEDIA_MOBILE_BREAKPOINT = 920;

const STEP_RATE = 'STEP_RATE';
const STEP_COMMENT = 'STEP_COMMENT';
const STEP_THANKS = 'STEP_THANKS';

const STEPS = [STEP_RATE, STEP_COMMENT, STEP_THANKS];

defineEmits(['close']);

const route = useRoute();

const currentStep = ref(STEP_RATE);
const comment = ref('');
const score = ref(-1);

const device =
  window.screen.width <= MEDIA_MOBILE_BREAKPOINT ? 'mobile' : 'desktop';

const selectScore = ({ scoreToSet }) => {
  score.value = scoreToSet;
  currentStep.value = STEP_COMMENT;
};

const handleSendFeedback = async () => {
  await sendNpsScore({
    score: score.value,
    comment: comment.value,
    formType: NPS_FORM_TYPE_LOGO_MAKER,
    device,
  });

  logEvent({
    name: LOGO_MAKER_FEEDBACK_SENT,
    properties: {
      rating: score.value,
      feedback: comment.value,
      location: route.name,
    },
  });

  currentStep.value = STEP_THANKS;
};

onMounted(() => {
  logEvent({
    name: LOGO_MAKER_FEEDBACK_SHOWN,
    properties: {
      location: route.name,
    },
  });
});
</script>

<template>
  <Modal @click-outside="$emit('close')">
    <div class="feedback-modal">
      <BackIcon
        v-if="currentStep === STEP_COMMENT"
        class="feedback-modal__back"
        @click="currentStep = STEPS[STEPS.indexOf(currentStep) - 1]"
      />
      <CloseIcon class="feedback-modal__close" @click="$emit('close')" />
      <div v-if="currentStep === STEP_RATE">
        <p class="feedback-modal__title">
          How would you rate logo maker service?
        </p>
        <div class="feedback-modal__ratings-row">
          <button
            v-for="rating in 10"
            :key="rating"
            v-qa="`feedback-modal-rating-${rating}`"
            theme="outline"
            class="feedback-modal__rating"
            @click="selectScore({ scoreToSet: rating })"
          >
            {{ rating }}
          </button>
        </div>
        <div class="feedback-modal__labels">
          <p>Poor</p>
          <p>Excellent</p>
        </div>
      </div>
      <div v-if="currentStep === STEP_COMMENT">
        <p class="feedback-modal__title">How can we improve your experience?</p>
        <div class="feedback-modal__input-container">
          <textarea
            v-model="comment"
            type="text"
            class="feedback-modal__input"
            placeholder="Write your feedback here"
          />
          <Button
            class="feedback-modal__button"
            size="medium"
            :disabled="!comment.length"
            @click="handleSendFeedback"
          >
            <p>Send</p>
          </Button>
        </div>
      </div>
      <div
        v-if="currentStep === STEP_THANKS"
        class="feedback-modal__thanks-container"
      >
        <CheckIcon class="feedback-modal__check" />
        <p class="feedback-modal__text">Thank you for your feedback!</p>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.feedback-modal {
  width: 520px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--light);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  line-height: 24px;

  &__button {
    margin-left: auto;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    color: var(--gray);
    font-size: 14px;
    margin-top: 8px;
  }

  &__input {
    padding: 12px 16px;
    width: 100%;
    border: 1px solid var(--gray-border);
    border-radius: 8px;
    min-height: 120px;
    resize: none;
    margin-bottom: 16px;

    &:focus {
      border: 1px solid var(--primary);
      transition: border 200ms ease-in-out;
    }
  }

  &__ratings-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }

  &__text {
    font-weight: 14px;
  }

  &__rating {
    border-radius: 8px;
    background-color: var(--light);
    padding: 8px;
    border: 1px solid var(--gray-border);
    height: 40px;
    width: 40px;
    font-size: 14px;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-light);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--gray-dark);
    margin: 0 24px 24px;
    text-align: center;
  }

  &__description {
    color: var(--gray);
    font-size: 14px;
    line-height: 1.71;
    margin-bottom: 24px;
  }

  &__back,
  &__close {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 8px;
    top: 8px;
    fill: var(--gray-dark);
    cursor: pointer;
  }

  &__close {
    right: 8px;
  }

  &__back {
    transform: rotate(90deg);
    left: 8px;
  }

  &__thanks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 248px;
  }

  &__check {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 768px) {
  .feedback-modal {
    padding: 24px;
    margin: 0 16px;
  }
}
</style>
