import { refreshAuthToken } from '@/api/AuthApi';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getAuthToken, getBearerToken, setAuthToken } from '@/utils/auth';
import { getLoginPageUrl } from '@/utils/getLoginPageUrl';

export const useAuthStore = defineStore('auth', () => {
  const refLocation = ref();
  const isAuthRedirecting = ref(false);
  const isTokenRefreshing = ref(false);
  const pendingRequests = ref<Array<() => void>>([]);

  const setRefLocation = (value: string) => {
    refLocation.value = value;
  };

  const setIsAuthRedirecting = (value: boolean) => {
    isAuthRedirecting.value = value;
  };

  const forceLogout = () => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const isRegister = !!urlParams.get('isRegister');

    getLoginPageUrl({ query: { ref: refLocation.value }, isRegister });
  };

  const addToPendingRequestsAndWaitForRefresh = () =>
    new Promise<void>((resolve) => {
      pendingRequests.value.push(resolve);
    });

  const releasePendingRequests = () => {
    pendingRequests.value.forEach((resolve) => resolve());
    pendingRequests.value = [];
  };

  const checkIfTokenIsRefreshing = async () => {
    if (!isTokenRefreshing.value) {
      return;
    }

    await addToPendingRequestsAndWaitForRefresh();
  };

  const refreshToken = async (tokenFromRequest: string) => {
    const currentToken = getBearerToken();
    const isTokenAlreadyRefreshed =
      tokenFromRequest && currentToken !== tokenFromRequest;

    if (isTokenRefreshing.value || isTokenAlreadyRefreshed) {
      return;
    }

    isTokenRefreshing.value = true;

    try {
      const { data } = await refreshAuthToken();

      if (!data.token) {
        forceLogout();

        return;
      }

      setAuthToken(data.token);
      releasePendingRequests();
    } catch (error) {
      forceLogout();
    }

    isTokenRefreshing.value = false;
  };

  return {
    getAuthToken,
    getBearerToken,
    setAuthToken,
    checkIfTokenIsRefreshing,
    refreshToken,
    forceLogout,
    isAuthRedirecting,
    setIsAuthRedirecting,
    setRefLocation,
  };
});
