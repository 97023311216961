<script setup>
import { onMounted, computed, ref } from 'vue';
import Button from '@/components/LogoMakerButton.vue';
import Modal from '@/components/LogoMakerModal.vue';
import Loader from '@/components/LogoMakerLoader.vue';
import { logEvent } from '@/api/AmplitudeApi';
import { SHOW_OFFER, CHOOSE_OFFER } from '@/constants/amplitude';
import { getPaymentLink } from '@/api/PaymentsApi';
import {
  useChargesStore,
  LOGO_MAKER_CREDITS_CHARGE_50,
} from '@/stores/charges';

defineEmits(['close']);
const props = defineProps({
  location: {
    type: String,
    required: true,
  },
});

const isPaymentRedirectLoading = ref(false);

const chargesStore = useChargesStore();
const creditCharge50 = computed(
  () => chargesStore.creditCharges[LOGO_MAKER_CREDITS_CHARGE_50],
);
const priceBeforeDiscount = computed(() => {
  const price = creditCharge50.value.price;
  return (price * 2).toFixed(2);
});

const handleContinue = async () => {
  try {
    isPaymentRedirectLoading.value = true;
    logEvent({
      name: CHOOSE_OFFER,
      properties: {
        location: props.location,
      },
    });
    const { paymentLink, token } = await getPaymentLink({
      itemId: LOGO_MAKER_CREDITS_CHARGE_50,
    });
    sessionStorage.setItem('payment-token', token);
    window.open(paymentLink, '_self');
  } catch (error) {
    console.error(error);
    isPaymentRedirectLoading.value = false;
  }
};

onMounted(() => {
  logEvent({
    name: SHOW_OFFER,
    properties: {
      location: props.location,
    },
  });
});
</script>

<template>
  <Modal @click-outside="$emit('close')">
    <div class="modal">
      <div class="modal__title">Purchase credits to generate AI-made logos</div>
      <div class="modal__subtitle">
        Generate 4 unique logos with every credit! Elevate your brand identity
        with the precision of artificial intelligence. Limited time offer – act
        now and stand out effortlessly!
      </div>
      <div class="modal__offer">
        <div class="modal__offer-container">
          <div class="modal__offer-name">
            {{ creditCharge50.name }}
          </div>
          <div class="modal__offer-discount">Save 50%</div>
        </div>
        <div class="modal__offer-container">
          <div class="modal__offer-price-before-discount">
            {{ `${creditCharge50.currencySymbol}${priceBeforeDiscount}` }}
          </div>
          <div class="modal__offer-price">
            {{ `${creditCharge50.currencySymbol}${creditCharge50.price}` }}
          </div>
        </div>
      </div>
      <div class="modal__actions">
        <button class="modal__cancel" @click="$emit('close')">Cancel</button>
        <Button
          size="medium"
          :disabled="isPaymentRedirectLoading"
          @click="handleContinue"
        >
          <Loader v-if="isPaymentRedirectLoading" />
          <template v-else>Continue</template>
        </Button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.modal {
  padding: 32px 40px 40px;
  background: var(--light);
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark);

  &__title {
    max-width: 600px;
    width: 100%;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 1.7;
    color: var(--gray-base);
    margin-bottom: 24px;
  }

  &__offer {
    width: 100%;
    padding: 15px 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    color: var(--dark);
    align-items: center;
    margin-bottom: 24px;
  }

  &__offer-discount {
    font-size: 12px;
    line-height: 1.33;
    font-weight: 700;
    text-transform: uppercase;
    padding: 4px 8px;
    background: var(--primary-light);
    border-radius: 4px;
    margin-right: auto;
    color: var(--primary);
  }

  &__offer-name {
    font-size: 14px;
    line-height: 1.7;
    margin-right: 16px;
  }

  &__offer-container {
    display: flex;
    align-items: center;
  }

  &__offer-price-before-discount {
    font-size: 12px;
    line-height: 1.66;
    text-decoration: line-through;
    color: var(--gray);
    margin-right: 8px;
  }

  &__offer-price {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    padding: 4px;
    border-radius: 4px;
    background: var(--gray-light);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
  }

  &__button {
    margin-bottom: 8px;
  }

  &__cancel {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 24px;
    font-weight: 700;
    color: var(--primary);
    cursor: pointer;
    background: transparent;
  }
}
@media screen and (max-width: 704px) {
  .modal {
    padding: 16px;
    margin: 16px;

    &__subtitle,
    &__offer {
      margin-bottom: 16px;
    }

    &__offer-price-before-discount {
      margin-right: 0;
    }

    &__offer-container {
      flex-direction: column-reverse;
      place-items: flex-end;
      gap: 8px;

      &:last-of-type {
        flex-direction: column;
      }
    }
  }
}
</style>
