import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import {
  getProfile,
  hasHostingPlan,
  createSessionFromToken,
} from '@/api/UserApi';
import {
  ICON_TYPES,
  ABSTRACT_ICON_DEFAULT,
  ICON_TYPES_V2,
  ABSTRACT_ICON,
} from '@/constants/iconTypes';
import { setAuthToken } from '@/utils/auth';

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const creditsBalance = ref(null);
  const userSettings = useStorage('user-settings', {
    brandName: '',
    brandSlogan: '',
    brandDescription: '',
    iconTypeIdAutoMode: ICON_TYPES_V2[ABSTRACT_ICON].id,
    iconTypeIdManualMode: ICON_TYPES[ABSTRACT_ICON_DEFAULT].id,
    isColorfulMode: false,
    iconDescriptionManualMode: '',
  });

  async function authorizeUser({ authToken, jwtToken }) {
    if (!jwtToken) {
      const { data } = await createSessionFromToken(authToken);
      setAuthToken(data.jwtToken);
    }
  }

  async function getUser() {
    try {
      const { hPanelId, isChargebee, balance } = await getProfile();
      user.value = {
        hPanelId,
        isChargebee,
      };
      creditsBalance.value = balance;

      try {
        await hasHostingPlan();
        user.value.isPaid = true;
      } catch {
        user.value.isPaid = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    user,
    userSettings,
    creditsBalance,
    authorizeUser,
    getUser,
  };
});
