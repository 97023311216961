<template>
  <EditorView
    class="editor-view"
    v-if="manualLogoData"
    :logoData="manualLogoData"
    is-ai-builder-banner-shown
    @update-logo="manualLogoData = $event"
    @download-logo="handleDownloadClick"
  />
  <div v-else class="loader-view">
    <Loader />
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import EditorView from '@/components/EditorView.vue';
import Loader from '@/components/LogoMakerLoader.vue';
import { useStorage, StorageSerializers } from '@vueuse/core';
import { useLogo } from '@/use/useLogo';
import { useUserStore } from '@/stores/user';

const emit = defineEmits(['download-logo']);

const { createLogo, downloadLogo } = useLogo();
const userStore = useUserStore();

const manualLogoData = useStorage('manualLogo', null, undefined, {
  serializer: StorageSerializers.object,
});

onBeforeMount(async () => {
  if (!manualLogoData.value) {
    manualLogoData.value = await createLogo({
      nameText: userStore.userSettings.brandName || 'Your Brand Name',
      sloganText: userStore.userSettings.brandSlogan,
      autoLayout: 'markLeft',
      logoType: 'manual',
    });
  }
});

const handleDownloadClick = (logoOptions) => {
  downloadLogo(logoOptions);

  emit('download-logo');
};
</script>

<style lang="scss" scoped>
.editor-view,
.loader-view {
  flex-grow: 1;
  width: 100%;
  height: auto;
}

.loader-view {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-dark);
}
</style>
