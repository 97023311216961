<template>
  <div
    v-if="faviconImageData"
    ref="faviconRef"
    :id="logoData.id"
    class="favicon-container"
  >
    <img
      v-if="faviconImageData.type === 'image'"
      class="favicon-container__content"
      :src="faviconImageData.content"
    />
    <div
      v-if="faviconImageData.type === 'svg'"
      class="favicon-container__svg-container"
      :style="{
        '--fill-color': faviconImageData.color,
      }"
      v-html="faviconImageData.content"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { debounce } from 'lodash';
import { useTextToSvg } from '@/use/useTextToSvg';

const props = defineProps({
  logoData: {
    type: Object,
    required: true,
  },
});

const { getSvg } = useTextToSvg();

const faviconImageData = ref(null);
const faviconRef = ref(null);
defineExpose({ faviconNode: faviconRef });

const setFaviconData = async () => {
  const logoMark = props.logoData.elements.find(({ id }) => id === 'mark');
  const logoName = props.logoData.elements.find(({ id }) => id === 'name');
  const logoSlogan = props.logoData.elements.find(({ id }) => id === 'slogan');

  if (logoMark.svg) {
    faviconImageData.value = {
      type: 'svg',
      content: logoMark.svg,
      color: logoMark.color,
    };
  } else if (logoMark.image) {
    faviconImageData.value = {
      type: 'image',
      content: logoMark.image,
    };
  } else if (logoName.text || logoSlogan.text) {
    const { svg } = await getSvg({
      text: logoName.text[0] || logoSlogan.text[0],
      fontFamily: logoName.fontFamily,
      fontSize: 24,
    });

    faviconImageData.value = {
      type: 'svg',
      content: svg,
      color: logoName.color,
    };
  } else {
    faviconImageData.value = {};
  }
};

watch(
  () => props.logoData,
  debounce(() => {
    setFaviconData();
  }, 1000),
  { deep: true, immediate: true },
);
</script>

<style lang="scss" scoped>
.favicon-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  &__content {
    max-width: 100%;
    max-height: 100%;
  }

  &__svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(svg) {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
      fill: var(--fill-color, #000);
    }
  }
}
</style>
