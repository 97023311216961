import httpService from '@/api/httpService';

export const hasHostingPlan = async () => {
  const { data } = await httpService.get(
    `${import.meta.env.VITE_BACKEND_API_URL}/logo-maker/has-hosting-plan`,
  );

  return data;
};

export const getProfile = async () => {
  const { data } = await httpService.get(
    `${import.meta.env.VITE_BACKEND_API_URL}/logo-maker/profile`,
  );

  return data;
};

export const createSessionFromToken = async (token) =>
  httpService.post(
    `${import.meta.env.VITE_BACKEND_API_URL}/hpanel/auth/${token}`,
  );
