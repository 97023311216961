<template>
  <Modal @click-outside="$emit('close')">
    <div class="download-modal">
      <div class="download-modal__columns">
        <div class="download-modal__column">
          <div class="download-modal__title margin-bottom-88">Free</div>
          <Button
            class="download-modal__button"
            type="outline"
            @click="$emit('download')"
          >
            Download
          </Button>
          <div class="download-modal__features">
            <p class="download-modal__feature">
              <CheckIcon class="download-modal__feature-icon" />
              Full commercial usage rights
            </p>
            <p class="download-modal__feature">
              <CheckIcon class="download-modal__feature-icon yellow-fill" />
              Low resolution PNG file
            </p>
            <!-- <p class="download-modal__feature">
              <CloseIcon class="download-modal__feature-icon red-fill" />
              Vector file
            </p> -->
          </div>
        </div>
        <div class="download-modal__column">
          <div class="download-modal__title">Premium</div>
          <div class="download-modal__subtitle">
            Available with any Website Builder or Hosting plan
          </div>
          <Button @click="redirectToPlans" class="download-modal__button">
            See plans
          </Button>
          <div class="download-modal__features">
            <p class="download-modal__feature">
              <CheckIcon class="download-modal__feature-icon" />
              Full commercial usage rights
            </p>
            <p class="download-modal__feature">
              <CheckIcon class="download-modal__feature-icon" />
              High resolution PNG file
            </p>
            <!-- <p class="download-modal__feature">
              <CheckIcon class="download-modal__feature-icon" />
              Vector file
            </p> -->
          </div>
        </div>
      </div>
      <CloseIcon @click="$emit('close')" class="download-modal__close" />
    </div>
  </Modal>
</template>

<script setup>
import { onMounted } from 'vue';
import Modal from '@/components/LogoMakerModal.vue';
import Button from '@/components/LogoMakerButton.vue';
import CheckIcon from '@/assets/icons/check.svg';
import CloseIcon from '@/assets/icons/close.svg';
import { logEvent } from '@/api/AmplitudeApi';
import { SHOW_OFFER, CHOOSE_OFFER } from '@/constants/amplitude';
import { openWebsiteBuilderOffer } from '@/utils/openWebsiteBuilderOffer';

defineEmits(['download', 'close']);

onMounted(() => {
  logEvent({
    name: SHOW_OFFER,
    properties: {
      location: 'ai_logo_maker_download',
    },
  });
});

const redirectToPlans = () => {
  openWebsiteBuilderOffer();
  logEvent({
    name: CHOOSE_OFFER,
    properties: {
      location: 'ai_logo_maker_download',
    },
  });
};
</script>

<style lang="scss" scoped>
.download-modal {
  max-width: 100%;
  max-height: 100%;
  width: 664px;
  border-radius: 8px;
  background-color: var(--light);
  position: relative;

  &__columns {
    display: flex;
  }

  &__column {
    flex: 1;
    padding: 64px 40px;

    &:not(:last-child) {
      border-right: 1px solid var(--gray-border);
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 32px;
  }

  &__button {
    margin-bottom: 16px;
    min-width: 194px;
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__feature {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__feature-icon {
    width: 24px;
    height: 24px;
    fill: var(--success);
  }

  &__close {
    position: absolute;
    width: 28px;
    top: 18px;
    right: 18px;
    fill: var(--gray-dark);
    cursor: pointer;
  }
}

.yellow-fill {
  fill: var(--warning);
}

.red-fill {
  fill: var(--danger);
}

.margin-bottom-88 {
  margin-bottom: 88px;
}

@media screen and (max-width: 704px) {
  .download-modal {
    width: 80%;
    height: 95%;
    overflow: auto;

    &__columns {
      flex-direction: column;
    }

    &__column {
      padding: 32px 24px;
      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid var(--gray-border);
      }
    }
  }
}
</style>
