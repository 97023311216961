<template>
  <div>
    <div
      ref="colorPickerButton"
      data-qa="builder-colorpicker-toggle-btn"
      @click="$emit('toggle')"
    >
      <slot>
        <div
          :style="{ backgroundColor: modelValue }"
          class="color-picker-button"
        />
      </slot>
    </div>

    <Popup
      v-if="isOpen"
      :target-ref="$refs.colorPickerButton"
      :placement="placement"
      :portal-selector="portalSelector"
      :offset="offset"
      :flip="flip"
      :auto-update="autoUpdate"
      @click-outside="$emit('click-outside')"
    >
      <div v-show="!isEyeDropperActive" class="color-picker-content">
        <button
          v-if="isEyeDropperAPISupported"
          class="color-picker-content__eye-dropper-button"
          @click="handleEyeDropper"
        >
          Pick color
        </button>

        <rgba-string-color-picker
          :color="colorThatRerendersColorPickerComponent"
          @color-changed="
            handleColorChange({
              value: $event.detail.value,
              reinitializeColorPicker: false,
            })
          "
        />

        <input
          ref="textInput"
          :value="textInputValue"
          class="color-picker-content__text-input"
          type="text"
          @keydown.enter="handleTextInput($event.target.value)"
          @blur="handleTextInput($event.target.value)"
          @input="debounceInputChange"
          @focus="$refs.textInput.select()"
        />
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/components/LogoMakerPopup.vue';
import 'vanilla-colorful/rgba-string-color-picker.js';
import tinycolor from 'tinycolor2';
import { debounce } from 'lodash';

import { defineComponent, ref, computed } from 'vue';

const DEFAULT_COLOR = 'rgb(255, 255, 255)';

export default defineComponent({
  components: {
    Popup,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: String,
      default: DEFAULT_COLOR, // This will be used when prop is not passed / undefined
    },
    // Positioning strategy
    placement: {
      type: String,
      default: 'bottom-start',
    },
    offset: {
      type: Number,
      default: 4,
    },
    portalSelector: {
      type: String,
      default: 'body',
    },
    autoUpdate: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:model-value', 'toggle', 'apply'],

  setup(props, { emit }) {
    const isEyeDropperActive = ref(false);
    // colorThatRerendersColorPickerComponent - on change rerenders <rgba-string-color-picker> component
    // We can't use this.currentColor, as this will cause <rgba-string-color-picker> reinitialization on every single change
    // Example unwanted behavior:
    // dragging a value to be #000 while having blue hue, will cause <rgba-string-color-picker> and reset hue to red
    const colorThatRerendersColorPickerComponent = ref('');

    // currentColor - used to locally track what color is currently selected in color picker.
    // props.modelValue not always represents currently selected value in color picker, because not all use cases
    // change color on @input event. Some of them do it only on @apply.
    const currentColor = ref(DEFAULT_COLOR);

    const isEyeDropperAPISupported = computed(() => 'EyeDropper' in window);

    const getColorValue = (color) => tinycolor(color).toRgbString();

    const textInputValue = computed(() =>
      tinycolor(getColorValue(currentColor.value)).toHexString(),
    );

    const setCurrentColor = (color) => {
      currentColor.value = getColorValue(color || DEFAULT_COLOR);
    };

    const handleColorChange = ({ value, reinitializeColorPicker = true }) => {
      setCurrentColor(value);

      if (reinitializeColorPicker) {
        colorThatRerendersColorPickerComponent.value = getColorValue(value);
      }

      emit('update:model-value', getColorValue(value));
    };

    const handleTextInput = (value) => {
      const color = tinycolor(value);

      if (!color.isValid()) {
        return;
      }

      handleColorChange({
        value,
      });
    };

    const handleEyeDropper = async () => {
      const eyeDropper = new window.EyeDropper();

      try {
        isEyeDropperActive.value = true;
        const selectedColor = await eyeDropper.open();

        handleColorChange({
          value: selectedColor.sRGBHex,
        });
      } finally {
        isEyeDropperActive.value = false;
      }
    };

    const debounceInputChange = debounce((event) => {
      handleTextInput(event.target.value);
    }, 1000);

    return {
      isEyeDropperActive,
      colorThatRerendersColorPickerComponent,
      isEyeDropperAPISupported,
      textInputValue,
      currentColor,
      setCurrentColor,
      handleColorChange,
      handleTextInput,
      handleEyeDropper,
      debounceInputChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.color-picker-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--gray-border);
  cursor: pointer;
}

.color-picker-content {
  width: 252px;
  padding: 16px;
  background-color: var(--light);
  border-radius: 8px;
  border: 1px solid var(--gray-border);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
  }

  &__edit {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: var(--primary);
    cursor: pointer;
  }

  &__text-input {
    width: 100%;
    padding: 8px 0;
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    letter-spacing: 0.25px;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    outline: none;

    &:hover,
    &:focus {
      border: 1px solid var(--primary);
    }
  }

  &__eye-dropper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--primary);
    cursor: pointer;
    border: 1px solid var(--gray-border);
    background-color: var(--light);
    border-radius: 4px;
    transition: border 0.2s;

    &:hover {
      border: 1px solid var(--primary);
    }
  }

  &__eye-dropper-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

rgba-string-color-picker {
  width: 100%;
  border-radius: 6px;

  &::part(saturation) {
    margin-bottom: 8px;
    border: 1px solid var(--gray-border);
    border-radius: 6px;
    box-shadow: none;
  }

  &::part(hue) {
    flex: 0 0 14px;
    margin-bottom: 8px;
    border: 1px solid var(--gray-border);
    border-radius: 6px;
  }

  &::part(alpha) {
    flex: 0 0 14px;
    border: 1px solid var(--gray-border);
    border-radius: 6px;
  }

  &::part(hue-pointer),
  &::part(saturation-pointer),
  &::part(alpha-pointer) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 3px solid rgb(255, 255, 255);
    box-shadow: 0 0 0 1px var(--gray-border);

    &::after {
      height: auto;
      border: 1px solid var(--gray-border);
    }
  }
}
</style>
